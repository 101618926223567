import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import labels from "./labels";
import {ChartBarIcon, ClockIcon, TrophyIcon} from "./Constants";
import DateSelector from './DateSelector'; // Importa il componente DateSelector

const Homepage = () => {
    const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
    const [setSelectedMatch] = useState(null);
    const navigate = useNavigate();
    const [matches, setMatches] = useState([]);
    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);
    const [selectedDay, setSelectedDay] = useState(0); // Stato per selezione giorno
    const [matchesByDay, setMatchesByDay] = useState({}); // Memorizza i match per giorno

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    useEffect(() => {
        const fetchMatches = async (day) => {
            try {
                const response = await fetch(`/api/matches?day=${day}`);
                const data = await response.json();
                setMatchesByDay(prevState => ({
                    ...prevState,
                    [day]: data, // Salva i dati per il giorno specifico
                }));
                if (selectedDay === day) {
                    setMatches(data); // Mostra i match per il giorno selezionato
                }
            } catch (error) {
                console.error('Error fetching daily matches:', error);
            }
        };

        // Carica i match solo se non sono già stati caricati per il giorno selezionato
        if (!matchesByDay[selectedDay]) {
            fetchMatches(selectedDay);
        } else {
            setMatches(matchesByDay[selectedDay]); // Usa i dati già caricati
        }
    }, [selectedDay, matchesByDay]);


    const handleMatchClick = (match) => {
        // Redirect to match-selector with query parameters
        const url = `/match?teamA=${encodeURIComponent(match.homeTeam)}&teamB=${encodeURIComponent(match.awayTeam)}&league=${encodeURIComponent(match.league)}`;
        navigate(url);
    };

    const handleClosePurchaseDialog = () => {
        setOpenPurchaseDialog(false);
        setSelectedMatch(null);
    };

    return (
        <div className="bg-gray-50 min-h-screen text-gray-900">

            {/* Matches Section */}
            <div className="container mx-auto px-4 py-12">
                {/* Date Selector */}
                <div className="container mx-auto px-4 py-4">
                    <DateSelector onSelect={(day) => setSelectedDay(day)} />
                </div>

                <h2 className="text-3xl font-bold mb-8 text-center">{currentLabels.todaysPredictions}</h2>
                <div className="bg-white shadow-lg rounded-xl overflow-hidden">
                    <div className="overflow-x-auto"> {/* Horizontal scroll wrapper */}
                        <table className="w-full">
                            <thead className="bg-gray-100 border-b">
                            <tr>
                                <th className="p-4 text-left text-gray-600">{currentLabels.match}</th>
                                <th className="p-4 text-left text-gray-600">{currentLabels.league}</th>
                                <th className="p-4 text-left text-gray-600">{currentLabels.time}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {matches
                                .sort((a, b) => a.league.localeCompare(b.league))
                                .map((match) => (
                                    <tr
                                        key={match.id}
                                        className="hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
                                        onClick={() => handleMatchClick(match)}
                                    >
                                        <td className="p-4 font-semibold">
                                            {match.homeTeam} vs {match.awayTeam}
                                        </td>
                                        <td className="p-4 text-gray-600">{match.league}</td>
                                        <td className="p-4 text-gray-600">
                                            {new Date(
                                                new Date(match.eventTime).setHours(
                                                    new Date(match.eventTime).getHours() + 1
                                                )
                                            ).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="container mx-auto px-4 py-2">
                <div className="grid md:grid-cols-3 gap-8">
                    {[
                        {
                            icon: <TrophyIcon/>,
                            title: currentLabels.banner1,
                            description: currentLabels.banner1description
                        },
                        {
                            icon: <ChartBarIcon/>,
                            title: currentLabels.banner2,
                            description: currentLabels.banner2description
                        },
                        {
                            icon: <ClockIcon/>,
                            title: currentLabels.banner3,
                            description: currentLabels.banner3description
                        }
                    ].map((feature, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-lg rounded-xl p-6 text-center hover:shadow-xl transition-all"
                        >
                            <div className="flex justify-center mb-4">{feature.icon}</div>
                            <h2 className="text-xl font-bold mb-3 text-gray-800">{feature.title}</h2>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Purchase Dialog */}
            {openPurchaseDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-2xl shadow-2xl p-8 w-[500px] text-center">
                        <h2 className="text-3xl font-bold mb-4 text-blue-800">{currentLabels.upgrade_to_pro_title}</h2>
                        <p className="text-gray-600 mb-6 text-lg">{currentLabels.upgrade_to_pro_description}</p>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-3 bg-gray-200 rounded-lg hover:bg-gray-300 font-semibold"
                                onClick={handleClosePurchaseDialog}
                            >
                                {currentLabels.cancel}
                            </button>
                            <button
                                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold"
                            >
                                {currentLabels.view_pro_plans}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Homepage;
