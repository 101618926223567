import React, { useState } from 'react';

const DateSelector = ({ onSelect }) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const dayAfter = new Date(today);
    dayAfter.setDate(dayAfter.getDate() + 2);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}/${month}`;
    };

    const [selectedId, setSelectedId] = useState(0);

    const dateOptions = [
        { id: 0, label: 'Today' },
        { id: 1, label: 'Tomorrow' },
        { id: 2, label: formatDate(dayAfter) }
    ];

    const handleSelect = (option) => {
        setSelectedId(option.id);
        onSelect?.(option.id);
    };

    return (
        <div className="inline-flex items-center h-9 divide-x divide-gray-100 bg-white rounded-lg shadow-sm border border-gray-200">
            {dateOptions.map((option) => (
                <button
                    key={option.id}
                    onClick={() => handleSelect(option)}
                    className={`
            h-full px-3 text-sm font-medium transition-colors duration-200
            ${selectedId === option.id
                        ? 'bg-indigo-50 text-indigo-600'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }
            first:rounded-l-lg last:rounded-r-lg
          `}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default DateSelector;