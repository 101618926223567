const labels = {
    en: {
        homePageTitle: "Advanced analytics and predictive modeling to elevate your betting strategy",
        back:"Back",
        price:"Price",
        buy:"Buy",
        availablePlans:"Available Plans:",
        login:"Login",
        logout:"Logout",
        predictions:"Predictions",
        successLogin:"Login successful!",
        failedLogin:"Login failed.",
        email:"Email",
        password:"Password",
        notHavingAccount:"Not having an account?",
        register:"Register",
        todaysPredictions:"Today's Predictions",
        match:"Match",
        league:"League",
        time:"Time",
        status:"Status",
        free:"Free",
        premium:"Premium",
        banner1:"Precision Predictions",
        banner1description:"Machine learning powered match predictions with high accuracy rates",
        banner2:"Data-Driven Insights",
        banner2description:"Comprehensive statistical analysis for informed betting decisions",
        banner3:"Real-Time Updates",
        banner3description:"Live match tracking and instant prediction adjustments",

        phone_number: 'Phone Number',
        last_name: 'Last Name',
        first_name: 'First Name',
        birth_date: 'Birth Date',
        password_confirmation: 'Confirm Password',
        accepts_terms: 'Accept Terms',
        success_message: 'Registration successful!',
        error_message: 'Registration failed.',
        invalid_phone_number: 'Enter a valid phone number.',
        invalid_birth_date: 'You must be 18 years or older.',
        invalid_first_name: 'First name cannot contain numbers.',
        invalid_last_name: 'Last name cannot contain numbers.',
        invalid_email: 'Please enter a valid email address.',
        invalid_password: 'Password must be at least 8 characters long.',
        invalid_password_confirmation: 'Passwords must match.',
        invalid_accepts_terms: 'You must accept the terms.',
        already_have_account: 'Already have an account?',
        confirm_password: 'Confirm Password',
        register_title: 'Register',
        registration_failed: 'Registration failed.',
        accepts_terms_error: 'You must accept the terms.',
        password_confirmation_error: 'Passwords must match.',
        password_error: 'Password must be at least 8 characters long.',
        email_error: 'Please enter a valid email address.',
        last_name_error: 'Last name cannot contain numbers.',
        first_name_error: 'First name cannot contain numbers.',
        birth_date_error: 'You must be 18 years or older.',
        phone_number_error: 'Enter a valid phone number.',
        terms_and_conditions: 'Terms and Conditions',
        register_button: 'Register',
        login_link: 'Log in',
        accept_terms: 'Accept the Terms and Conditions',


        payment_failed: 'Payment Failed',
        payment_failed_description: 'Unfortunately, your payment could not be processed.',
        payment_failed_instructions: 'Please check your payment details or try again later. If the issue persists, contact our support team for assistance.',
        payment_error_message: 'Something went wrong while processing your payment. Please try again.',
        need_help: 'Need help?',
        contact_support: 'You can reach out to our support team at',
        support_email: 'gizzoro.bettings@gmail.com',
        retry_payment: 'Retry Payment',


        payment_successful: 'Payment Successful!',
        payment_success_description: 'Your payment has been processed successfully. Thank you for using GizzoBet.',
        payment_success_instructions: 'We are excited to have you as a part of our community. You can now enjoy all the features and benefits of your subscription.',
        payment_verified: 'Payment has been successfully verified!',
        view_invoice: 'View Invoice:',
        copy_invoice_url: 'Copy Invoice URL',
        important: 'Important:',
        cancel_auto_renewal: 'To cancel the auto-renewal of your subscription, please send an email to',
        go_to_dashboard: 'Go to Dashboard',

        page_not_found: '404',
        page_not_found_description: "Oops! The page you’re looking for doesn’t exist.",
        go_back_home: 'Go Back Home',


        upgrade_to_pro_title: 'Upgrade to Pro',
        upgrade_to_pro_description: 'Unlock premium predictions and advanced analytics for this match.',
        cancel: 'Cancel',
        view_pro_plans: 'View Pro Plans',

        monthly:'monthly',
        annual:'annual',
        weekly:'weekly',
        daily:'daily',

        title: "Select Match",
        totalLeague: "All Leagues",
        firstSquad: "Squad 1",
        secondSquad: "Squad 2",
        cents: "Displays values in 00.00",
        download: "Download",
        showStatistics: "Show Statistics",
        statistics: "Statistics",
        termsAndContitions: "Terms and Conditions",
        statsMap: {
            "final_avg_score": "Expected Goals",
            "final_avg_shot": "Expected Shots",
            "final_avg_shot_on_target": "Expected Shots on Target",
            "final_avg_corner_kick": "Expected Corners",
            "final_avg_foul": "Expected Fouls",
            "final_avg_offside": "Expected Offsides",
            "final_avg_card": "Expected Cards"
        }
    },
    ro: {
        homePageTitle: "Analize avansate și modelare predictivă pentru a-ți îmbunătăți strategia de pariere.",
        price:"Preț",
        buy:"Cumpără",
        back:"Înapoi",
        availablePlans:"Planuri disponibile:",
        login:"Autentificare",
        logout:"Deconectare",
        predictions:"Predicții",
        successLogin:"Autentificare reușită!",
        failedLogin:"Autentificare eșuată.",
        email:"Email",
        password:"Parolă",
        notHavingAccount:"Nu ai un cont?",
        register:"Înregistrare",
        todaysPredictions:"Predicții de azi",
        match:"Meci",
        league:"Ligă",
        time:"Timp",
        status:"Stare",
        free:"Gratuit",
        premium:"Premium",
        banner1: "Predicții Precise",
        banner1description: "Predicții pentru meciuri bazate pe învățarea automatizată cu rate de acuratețe ridicate",
        banner2: "Informații Bazate pe Date",
        banner2description: "Analiză statistică completă pentru decizii de pariu informate",
        banner3: "Actualizări în Timp Real",
        banner3description: "Urmărirea meciurilor live și ajustări instantanee ale predicțiilor",

        phone_number: 'Număr de telefon',
        last_name: 'Nume de familie',
        first_name: 'Prenume',
        birth_date: 'Dată naștere',
        password_confirmation: 'Confirmă parola',
        accepts_terms: 'Acceptă Termenii',
        success_message: 'Înregistrare reușită!',
        error_message: 'Înregistrare eșuată.',
        invalid_phone_number: 'Introduceți un număr de telefon valid.',
        invalid_birth_date: 'Trebuie să aveți cel puțin 18 ani.',
        invalid_first_name: 'Prenumele nu poate conține cifre.',
        invalid_last_name: 'Numele de familie nu poate conține cifre.',
        invalid_email: 'Vă rugăm să introduceți o adresă de email validă.',
        invalid_password: 'Parola trebuie să aibă cel puțin 8 caractere.',
        invalid_password_confirmation: 'Parolele trebuie să corespundă.',
        invalid_accepts_terms: 'Trebuie să acceptați termenii.',
        already_have_account: 'Aveți deja un cont?',
        confirm_password: 'Confirmă parola',
        register_title: 'Înregistrare',
        registration_failed: 'Înregistrare eșuată.',
        accepts_terms_error: 'Trebuie să acceptați termenii.',
        password_confirmation_error: 'Parolele trebuie să corespundă.',
        password_error: 'Parola trebuie să aibă cel puțin 8 caractere.',
        email_error: 'Vă rugăm să introduceți o adresă de email validă.',
        last_name_error: 'Numele de familie nu poate conține cifre.',
        first_name_error: 'Prenumele nu poate conține cifre.',
        birth_date_error: 'Trebuie să aveți cel puțin 18 ani.',
        phone_number_error: 'Introduceți un număr de telefon valid.',
        terms_and_conditions: 'Termeni și condiții',
        register_button: 'Înregistrează-te',
        login_link: 'Conectează-te',
        accept_terms: 'Acceptă Termenii și Condițiile',


        payment_failed: 'Plata eșuată',
            payment_failed_description: 'Din păcate, plata dumneavoastră nu a putut fi procesată.',
        payment_failed_instructions: 'Vă rugăm să verificați detaliile de plată sau să încercați din nou mai târziu. Dacă problema persistă, contactați echipa noastră de asistență pentru ajutor.',
        payment_error_message: 'Ceva a mers greșit în timpul procesării plății. Vă rugăm să încercați din nou.',
        need_help: 'Aveți nevoie de ajutor?',
        contact_support: 'Puteți contacta echipa noastră de suport la',
        support_email: 'gizzoro.bettings@gmail.com',
        retry_payment: 'Reîncercați plata',


        payment_successful: 'Plata reușită!',
        payment_success_description: 'Plata dumneavoastră a fost procesată cu succes. Vă mulțumim că folosiți GizzoBet.',
        payment_success_instructions: 'Suntem încântați să vă avem ca parte a comunității noastre. Acum puteți beneficia de toate funcțiile și beneficiile abonamentului dumneavoastră.',
        payment_verified: 'Plata a fost verificată cu succes!',
        view_invoice: 'Vizualizați Factura:',
        copy_invoice_url: 'Copiați URL-ul facturii',
        important: 'Important:',
        cancel_auto_renewal: 'Pentru a anula reînnoirea automată a abonamentului, vă rugăm să trimiteți un e-mail la ',
        go_to_dashboard: 'Accesați panoul de control',

        page_not_found: '404',
        page_not_found_description: "Ups! Pagina pe care o căutați nu există.",
        go_back_home: 'Întoarce-te la început',

        upgrade_to_pro_title: 'Treceți la Pro',
        upgrade_to_pro_description: 'Deblocați predicții premium și analize avansate pentru acest meci.',
        cancel: 'Anulează',
        view_pro_plans: 'Vezi planurile Pro',

        monthly:'lunar',
        annual:'anual',
        weekly:'săptămânal',
        daily:'zilnic',

        title: "Selectează Meciul",
        totalLeague: "Toate Campionatele",
        firstSquad: "Echipa 1",
        secondSquad: "Echipa 2",
        cents: "Afișează valorile în 00.00",
        download: "Descarcă",
        showStatistics: "Afișează Statistici",
        statistics: "Statistici",
        termsAndContitions: "Termeni și condiții",
        statsMap: {
            "final_avg_score": "Expected Goluri",
            "final_avg_shot": "Expected Şuturi",
            "final_avg_shot_on_target": "Expected Şuturi pe Poartă",
            "final_avg_corner_kick": "Expected Cornere",
            "final_avg_foul": "Expected Faulturi",
            "final_avg_offside": "Expected Offside-uri",
            "final_avg_card": "Expected Cartonaşe",
        }
    },
};

export default labels;